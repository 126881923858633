import Page from './Page.tsx'
import { Button, CircularProgress } from "@mui/material"
import { context } from "../../index.js"
import { observer } from "mobx-react-lite"
import PageFinal from "./PageFinal.tsx"


export default observer(function Questionnaire () {
    return (
        <div className={context.submitting ? "questionnaire disabled" : "questionnaire"}>
            <QuestionnaireHeader />
            <QuestionnaireBody />
        </div>
    )
})


const QuestionnaireHeader = observer(() => {
    
    const { pageIndex, previewPage, template } = context
    var submittedPages = context.getSubmittedPages()

    if (previewPage) {
        return (
            <div className="questionnaire-header">
                <Button variant="contained" className="btn btn-sm" onClick={() => context.setPreviewPage(null)}>
                    <h4>Exit page preview</h4>
                </Button>
                <small>You are previewing this page</small>
            </div>
        )
    }
        
    return (
        <div className="questionnaire-header">
            <Button
                disabled={pageIndex === 0 || submittedPages?.length < 1}
                className="btn btn-sm"
                variant="contained"
                onClick={() => context.backPage()}>
                <h4>Go back</h4>
            </Button>
            <small>{template.displayName}</small>
        </div>
    )
})


const QuestionnaireBody = observer(() => {

    const { previewPage, submitting, pageIndex, getSubmittedPages, getUnsubmittedPages } = context

    var unsubmittedPages = getUnsubmittedPages()
    var submittedPages = getSubmittedPages()

    if (submitting) return <CircularProgress style={{width: "18px", height: "18px", margin: "50px 100px"}} />

    if (previewPage) return <Page page={previewPage} />

    if (pageIndex !== null) return <Page page={submittedPages[pageIndex]} />

    if (unsubmittedPages.length) return <Page page={unsubmittedPages[0]} />

    return <PageFinal />
})