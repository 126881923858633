import { ThemeProvider } from '@mui/material/styles'
import { BrowserRouter } from 'react-router-dom'
import ErrorBoundary from './misc/ErrorBoundary'
import {Theme} from './misc/Theme.ts'
import ReactDOM from 'react-dom/client'
import AppContext from './AppContext.ts'
import App from './components/App'
import Errors from './components/Errors.tsx'
import React from 'react'
import './style/App.scss'

export const context = new AppContext()
export const ReactContext = React.createContext(context)

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
	<BrowserRouter>
		<ErrorBoundary>
			<ThemeProvider theme={Theme}>
				<ReactContext.Provider value={context}>
					<App />
					<Errors />
				</ReactContext.Provider>
			</ThemeProvider>
		</ErrorBoundary>
	</BrowserRouter>
)