import * as Yup from "yup"
import { Button } from "@mui/material"
import { Icons } from "../../misc/Icons"
import { useFormik } from "formik"
import Ajax from "../../Ajax"
import { context } from "../.."
import { IFieldProps, IUpload } from "../../interfaces"
import FieldRadio from "./FieldRadio"

interface FormValues {
    file: File | null;
    evidence_type: string | null;
}

interface FileWithMetadata extends File {
    type: string;
    size: number;
}

const getTypesValidationMessage = (allowedFormats) => {
    var al = [...allowedFormats]
    // Add commers
    if (al.length > 2) {
        al = al.map((type, i) => {
            return i < allowedFormats.length - 2 ? `${type},` : type
        })
    }
    // Add 'or'
    if (al.length > 1) {
        var indexOneFromEnd = al.length - 1
        al.splice(indexOneFromEnd, 0, "or")
    }
    return `Your file must be a ${al.join(" ")} file`
}



export default function FieldFile ({ formik, field }: IFieldProps) {

    var upload: IUpload = context.uploads.find((u) => u.uid === formik.values[field.name])

    // Format validation
    var fileFormats = context.getListSet("file_formats")
    var allowedFormats = field.allowed_formats ? field.allowed_formats : Object.keys(fileFormats)
    var allowedMimeTypes = allowedFormats.map(type => fileFormats[type])

    // File size validation
    var maxBytes = field.max ? field.max : 1000000
    var maxKb = (maxBytes / 1000).toFixed(1)
    var maxMb = (maxBytes / 1000000).toFixed(1)
    var fileSizeMessage = maxBytes >= 1000000 ? `File size must be less than ${maxMb}MB` : `File size must be less than ${maxKb}KB`

    var initialEvidenceType = field.evidence_types?.length === 1 ? field.evidence_types[0] : null

    const fileFormik = useFormik<FormValues>({

        initialValues: {
            file: null,
            evidence_type: upload?.evidenceType ? upload?.evidenceType : initialEvidenceType
        },

        validationSchema: Yup.object({
            file: Yup.mixed<FileWithMetadata>()
                .required('A file is required')
                .test('fileFormat', getTypesValidationMessage(allowedFormats), value => {
                    return value && allowedMimeTypes.includes(value.type);
                })
                .test('fileSize', fileSizeMessage, value => {
                    return value && value.size <= field.max;
                }),
        }),

        onSubmit: async (values, actions) => {
            await context.uploadFile(values.file, values.evidence_type, field.name).then((upload) => {
                formik.setFieldValue(field.name, upload.uid)
                context.submitPageAnswers({...formik.values, [field.name]: upload.uid})
            }).catch((response) => {
                fileFormik.setFieldError("file", response.data?.detail ? response.data.detail : "Upload failed")
            })
        }
    })

    const pasteFromClipboard = (e) => {
        var file = null
        const items = [...(e.clipboardData || e.originalEvent.clipboardData).items];
        items.forEach(item => {
            if (items.length && items[0].kind === "file") {
                file = items[0].getAsFile()
            }
        })

        if (file) {
            fileFormik.setFieldValue("file", file)
        }
    }

    var evidenceTypesOptions = {}
    field.evidence_types.forEach((et) => {
        evidenceTypesOptions[et] = et
    })
    var prototypeSelectField = {type: "select", label: field.label, name: "evidence_type", options: evidenceTypesOptions}

    return (
        <div className={fileFormik.isSubmitting ? "form-field field-file disabled" : "form-field field-file"}>

            {/* {field.label ? <label>{field.label}</label> : null} */}

            {field.evidence_types?.length > 1 ? (
                <div className={formik.values[field.name] || fileFormik.values.file ? "disabled" : ""}>
                    <FieldRadio formik={fileFormik} field={prototypeSelectField} />
                </div>
            ) : null}

            {formik.values[field.name] ? (
                <FileUploaded formik={formik} field={field} />
            ) : (
                <div className={!fileFormik.values.evidence_type ? "disabled" : ""}>
                    {fileFormik.values.evidence_type ? (
                        <label>Upload a copy of your {fileFormik.values.evidence_type}</label>
                    ) : (
                        <label>Upload your evidence</label>
                    )}

                    <div style={{display: "flex", alignItems: "start", gap: "6px", margin: "10px 0 0 15px"}}>
                        <label htmlFor="file" tabIndex={1} className="select-files-icon">
                            <Icons.UploadFile />
                        </label>

                        <input
                            hidden
                            type="file"
                            id="file"
                            name="file"
                            multiple={false}
                            onChange={(e) => {
                                if (e.target.files?.length) {
                                    fileFormik.setFieldValue("file", e.target.files[0])
                                }
                            }}
                        />

                        {fileFormik.values.file ? (
                            <div className="field-file-single-item">
                                <span style={{marginRight: "auto"}}>{fileFormik.values.file.name}</span>
                                
                                <Button
                                    className="btn btn-sm"
                                    variant="contained"
                                    color="info"
                                    onClick={() => fileFormik.setFieldValue("file", null)}>
                                    Cancel
                                </Button>

                                <Button
                                    className="btn btn-sm"
                                    variant="contained"
                                    color="success"
                                    onClick={() => fileFormik.handleSubmit()}>
                                    Upload
                                </Button>
                            </div>
                        ) : (
                            <input
                                value=""
                                className="file-paste-input"
                                onPaste={(e) => pasteFromClipboard(e)}
                                placeholder="or Ctrl+V to paste"  />
                        )}
                    </div>

                    {fileFormik.submitCount && fileFormik.errors.file ? (
                        <span className="warning">{fileFormik.errors.file?.toString()}</span>
                    ) : null}

                    {field.helpText ? (
                        <span className="help-text">{field.helpText}</span>
                    ) : null}
                </div>
            )}
            
            {formik.submitCount && formik.errors[field.name] ? (
                <span className="warning">{formik.errors[field.name]}</span>
            ) : null}
        </div>
    )
}


function FileUploaded ({formik, field}) {

    var upload: IUpload = context.uploads.find((u) => u.uid === formik.values[field.name])

    return (
        <div className="form-field-item-uploaded">
            <Icons.Photo />
            {upload ? <p>{upload.displayName}</p> : <p>File has been uploaded</p>}
            <Button
                className="btn btn-sm"
                variant="contained"
                color="error"
                onClick={async () => {
                    await Ajax.Upload.Delete(formik.values[field.name]).catch((response) => {
                        console.log(response)
                    }).finally(() => {
                        formik.setFieldValue(field.name, null)
                    })
                }}>
                Delete attachment
            </Button>
        </div>
    )
}