import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material"
import React from "react"
import { context } from "../.."

export default function FieldRadio ({formik, field, autoFocus = false}) {
    return (
        <div className="form-field field-radio-select">
            <FormControl>
                {field.label ? <FormLabel>{field.label}</FormLabel> : null}
                
                {field.helpText ? <span className="help-text" style={{marginTop: "4px"}}>{field.helpText}</span> : null}
                
                <RadioGroup
                    name={field.name}
                    value={formik.values[field.name]}
                    style={{margin: "10px 0 10px 15px"}}
                    onChange={formik.handleChange} >

                    {Object.keys(field.options)?.map((key, i) => {
                        var label = context.formatLabel(field.options[key])
                        // Don't show options with placeholders remaining
                        if (label.includes("{") || label.includes("}")) {
                            return <React.Fragment key={i}></React.Fragment>
                        }
                        
                        return (
                            <FormControlLabel
                                key={i}
                                value={key}
                                label={label}
                                control={
                                    // Apply autoFocus to the first option
                                    <Radio inputProps={{autoFocus: i === 0 ? autoFocus : false}} />
                                } />
                        )
                    })}
                </RadioGroup>
                {formik.submitCount && formik.errors[field.name] ? <span className="warning">{formik.errors[field.name]}</span> : null}
            </FormControl>
        </div>
    )
}