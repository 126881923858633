import axios, { AxiosResponse } from 'axios'
import { context } from '.';
// import { context } from '.';

const host = process.env.NODE_ENV === 'development' 
    ? "http://localhost:8002"
    : ""

var isWaitingForTokenRefresh = false

const refreshToken = async () => {

    // If there is already refreshToken request underway, wait for a second and then resolve.
    // This will trigger the original request which should have a valid token by this time.
    if (isWaitingForTokenRefresh === true) {
        return await new Promise(resolve => setTimeout(resolve, 1000));
    }

    isWaitingForTokenRefresh = true

    try {
        var {data} = await axios_unintercepted.post("session/refresh-token")
        // store.AppStore.setToken(data.token)
        return Promise.resolve()
    } catch {
        // await store.AppStore.resetApp()
        return Promise.reject()
    } finally{
        isWaitingForTokenRefresh = false
    }
}

const assignAuthorizationHeader = (config) => {
    const token = window.localStorage.getItem("aora_questionnaire_jwt")
    if (token) {
        config.headers.Authorization = `Bearer ${token}`
    }
    return config
}

// Create the main axios instance
const axios_main = axios.create();
axios_main.defaults.withCredentials = true;
axios_main.defaults.baseURL = host
axios_main.interceptors.request.use(config => {
    return assignAuthorizationHeader(config)
})

// Create a seperate axios instance without the response interceptor.
// We can use this to make requests from the response interceptor
// and avoid triggering infinite loops of requests.
const axios_unintercepted = axios.create();
axios_unintercepted.defaults.withCredentials = true;
axios_unintercepted.defaults.baseURL = host
axios_unintercepted.interceptors.request.use(config => {
    return assignAuthorizationHeader(config)
})

axios_unintercepted.interceptors.response.use(
    async (response) => { // Success
        return Promise.resolve(response)
    },
    async (error) => { // Fail
        return Promise.reject(error.response)
    }
)

// Add response interceptor to main axios instance
// ---------------------------------------------------------------------------------------------
axios_main.interceptors.response.use(

    async (response) => { // Success
        return Promise.resolve(response)
    },

    async (error) => { // Fail
        if (!error.response) {
            return Promise.reject(error.response)
        }

        const {status, data, config} = error.response

        // Handle errors by http status
        switch (status) {
            case 401:
                // return await refreshToken().then(() => {
                //     return Promise.resolve(axios_main.request(config)) // Resolve the original request as if nothing happened
                // })
                context.setToken(null)
                // context.displayError("Unauthenticated")
                return Promise.reject(error.response)
            case 440: // This code is used by the server when it wants to force the session to end.
                // await store.AppStore.logout()
                // AlertModal({title: data.title, body: data.detail})
                return Promise.reject(error.response)

            default:
                break
        }

        return Promise.reject(error.response)
    }
)

// Make all successful requests resolve a promise
// ---------------------------------------------------------------------------------------------
const returnResponse = (response: AxiosResponse) => { return Promise.resolve(response) }


// Define the behaviour for each type of request
// ---------------------------------------------------------------------------------------------
const requests = {
    get: (url: string) => axios_main.get(url).then(returnResponse),
    put: (url: string, data: any) => axios_main.put(url, data).then(returnResponse),
    post: (url: string, data: any) => axios_main.post(url, data).then(returnResponse),
    delete: (url: string) => axios_main.delete(url).then(returnResponse),
    fileDownload: (url: string, fileName: string) => axios_main.get(url, {responseType: 'blob'}).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', fileName) //or any other extension
        document.body.appendChild(link)
        link.click()
    }),
    fileUpload: (url: string, data: any, config: any) => axios_main.post(url, data, {
        cache: false,
        contentType: "image/png",
        processData: false,
        ...config
    }).then(returnResponse),
}


export default requests;