import React from 'react'
import { ITable } from '../../interfaces'
import { Button, Modal } from "@mui/material";
import FormFields from './FormFields';
import TruncText from '../../misc/TruncText';
import { context } from '../..';

interface ITableProps {
    table: ITable;
    onSubmit: Function
}

export default function Table ({
    table,
    onSubmit = (values) => {}
}: ITableProps) {

    const valueSets = context.answers[table.list_name]
    const maxColumnsToShow = Math.floor(window.screen.width / 120)

    const [error, setError] = React.useState(null)
    const [rows, setRows] = React.useState(valueSets ? valueSets : [])

    // If columns are not specified, derive them fields
    if (!table.columns) {
        table.columns = {}
        table.fields.forEach(field => {
            table.columns[field.name] = field.label
        })
    }

    const addRow = (newRow) => {
        setError(null)
        var newList = [...rows, newRow]
        setRows(newList)
        context.submitPageAnswers({[table.list_name]: newList})
    }

    const removeRow = (index) => {
        setError(null)
        var newList = rows.filter((row, i) => i !== index)
        setRows(newList)
        context.submitPageAnswers({[table.list_name]: newList})
    }

    const updateRow = (index, updatedRow) => {
        var newList = []
        rows.forEach((row, i) => {
            newList.push(index === i ? updatedRow : row)
        })
        setRows(newList)
        context.submitPageAnswers({[table.list_name]: newList})
    }

    const handleSubmit = () => {
        if (table.min_rows && rows?.length < table.min_rows) {
            return setError(`You must provide at least ${table.min_rows} entr${table.min_rows > 1 ? "ies" : "y"}`)
        }

        if (table.max_rows && rows?.length > table.max_rows) {
            return setError(`You must provide no more than ${table.max_rows} entr${table.max_rows > 1 ? "ies" : "y"}`)
        }
        onSubmit({[table.list_name]: rows})
    }
    
    return (
        <div className="table-section">
            <div className="table">

                <div className="table-headings">
                    {Object.values(table.columns).filter((fieldKey, i) => i < maxColumnsToShow).map((label: string, i) => (
                        <div key={i} className="table-cell">{label}</div>
                    ))}
                    <div className="table-cell"></div>
                </div>

                <div className="table-body">
                    {rows.map((row, i) => (
                        <div key={i} className="table-row">
                            {Object.keys(table.columns).filter((fieldKey, i) => i < maxColumnsToShow).map((fieldKey, i) => (
                                <div key={i} className="table-cell">
                                    <TruncText text={row[fieldKey]} />
                                </div>
                            ))}
                            
                            <div className="table-cell">
                                <div style={{display: "flex", gap: "5px", marginLeft: "auto"}}>
                                    <RowFormModal 
                                        row={row} 
                                        table={table} 
                                        opener={<Button className="btn btn-xs" variant="contained">Edit</Button>}
                                        onSubmit={(updatedRow) => updateRow(i, updatedRow)} />
                                    
                                    <Button 
                                        onClick={() => removeRow(i)}
                                        className="btn btn-xs" 
                                        color="error" 
                                        variant="contained">
                                        Remove
                                    </Button>
                                </div>
                            </div>
                        </div>
                    ))}

                    {!rows.length ? (
                        <div className="table-row">
                            <div className="table-cell">
                                No entries
                            </div>
                        </div>
                    ) : null}
                </div>

                {error ? <div className="warning">{error}</div> : null}
            </div>
            

            <br/>

            <RowFormModal 
                table={table} 
                row={{}} 
                onSubmit={addRow} 
                opener={<Button  className="btn btn-sm" variant="contained">Add new {table.list_item_label}</Button>} />

            <br/>

            <Button 
                type="submit" 
                color="success"
                variant="contained"
                className="btn btn-lg" 
                disabled={context.locked}
                onClick={() => handleSubmit()}>
                Next
            </Button>

            {context.locked ? <small>This questionnaire is locked</small> : null}
        </div>
    )
}


function RowFormModal ({
    row, 
    table, 
    opener,
    onSubmit = (newRow) => {}
}) {

    const [open, setOpen] = React.useState(false)

    return (
        <>
            <span onClick={() => setOpen(true)}>
                {opener}
            </span>
            <Modal
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <div className="modal">
                    <div className="modal-body">
                        <div style={{display: "flex", justifyContent: "space-between"}}>
                            <h3>Add new {table.list_item_label ? table.list_item_label : "row"}</h3>
                        </div>
                        
                        <FormFields 
                            maxHeight="70vh" 
                            initialValues={row} 
                            fields={table.fields} 
                            onCancel={() => setOpen(false)}
                            onSubmit={(values) => {
                                var newRow = {}
                                table.fields.forEach(field => {
                                    newRow[field.name] = values[field.name]
                                })
                                onSubmit(newRow)
                                setOpen(false)
                        }} />
                    </div>
                </div>
            </Modal>
        </>
    )
}