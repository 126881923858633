import React from "react"
import Logo from "../misc/Logo.tsx"
import { context } from "../index.js"
import { observer } from "mobx-react-lite";
import { LinearProgress } from "@mui/material"
import Questionnaire from "./questionnaire/Questionnaire.tsx"
import { Route, Routes, useParams, useNavigate } from "react-router-dom"
import DevTools from "./DevTools.tsx";
import Header from "./Header.tsx";
import LoginPage from "./LoginPage.tsx";


export default function App() {
	return (
		<Routes>
			<Route path="/" element={<FallbackPage />} />
			<Route path="/:advisorUid/*" element={<AdvisorLoaded />} />
			{/* <Route path="/:advisorUid/:questionnaireUid/*" element={<AdvisorLoaded />} /> */}
		</Routes>
	)
}


const AdvisorLoaded = observer((props) => {

	var params = useParams()
	var navigate = useNavigate()

	React.useEffect(() => {
		context.getAdvisorAccount(params.advisorUid).catch((response) => {
			navigate("/")
		})
	}, [])

	if (!context.advisorAccount) return <LinearProgress />

	return (
		<div className="app">
			<Header />
			<main>
				<div className="main-inner">
					<Routes>
						<Route exact path="/" element={<FallbackPage />} />
						<Route path="/:questionnaireUid/*" element={<QuestionnaireLoaded />} />
					</Routes>
				</div>
			</main>
		</div>
	)
})

const QuestionnaireLoaded = observer((props) => {

	var params = useParams()
	const { template, answers, token } = context

	React.useEffect(() => {
        if (!template && params.questionnaireUid) {
            context.loadQuestionnaire(params.questionnaireUid)
        }
    }, [token])

	if (!token) return <LoginPage />

    if (!template) return <LinearProgress />

	return (
		<Routes>
			<Route exact path="/" element={<Questionnaire key={answers.submitted_pages} />} />
			<Route path="/dev-tools" element={<DevTools />} />
		</Routes>
	)
})


function FallbackPage () {
	return (
		<div className="main-inner">
			<div style={{height: "100vh", paddingTop: "15vh"}}>
				<h1 style={{fontSize: "8vw", fontWeight: "800", lineHeight: "8vw"}}>Data Driven Questionnaires</h1>
				<h1 style={{fontWeight: "600", marginTop: "15px"}}>by <Logo width={80} type="block" /></h1>
				<p>Keeping data-entry to a minimum</p>
			</div>
		</div>
	)
}