import { observer } from "mobx-react-lite";
import { context } from "..";
import Logo from "../misc/Logo";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default observer(function Header () {

	var navigate = useNavigate()

    const DevToolsLink = () => {
        if (!context.advisorAccount.displayName.toLowerCase().includes("smithers")) return <></>
        if (window.location.pathname.includes("dev-tools")) {
            return (
                <Button
                    className="btn btn-sm" 
                    onClick={() => navigate(`/${context.advisorAccount.uid}/${context.questionnaireUid}`)}>
                    Questionnaire
                </Button>
            )
        } else {
            return (
                <Button
                    className="btn btn-sm" 
                    onClick={() => navigate(`/${context.advisorAccount.uid}/${context.questionnaireUid}/dev-tools`)}>
                    Dev tools
                </Button>
            )
        }
    }

    return (
        <header>
            <div className="main-inner">
                {context.advisorAccount.logoUri 
                    ? <img src={context.advisorAccount.logoUri} alt="logo" height="40" />
                    : <Logo width={100} type="block" />}

                <div style={{display: "flex", gap: "10px"}}>
                    {context.token ? (
                        <>
                            <DevToolsLink />
                            
                            <Button 
                                className="btn btn-sm" 
                                onClick={() => context.setToken(null)}>
                                Log out
                            </Button>
                        </>
                    ) : null}
                </div>
            </div>
        </header>
    )
})