import { IFieldProps } from '../../interfaces'
import { TextField } from "@mui/material";
import { isValid } from 'date-fns';
import FieldFile from './FieldFile';
import { context } from '../..';
import { observer } from 'mobx-react-lite';
import FieldSelect from './FieldSelect';
import FieldRadio from './FieldRadio';


export default observer(function Field ({field, formik, autoFocus = false}: IFieldProps) {

    const label = context.formatLabel(field.label)

    switch (field.type) {
        case "file":
            return <FieldFile key={formik.values[field.name]} formik={formik} field={field} />
            
        case "checkbox":
            return (
                <div className="form-field field-checkbox">
                    <label>
                        <input
                            name={field.name}
                            checked={formik.values[field.name] === "true"}
                            type="checkbox"
                            onChange={(e) => {
                                formik.setFieldValue(field.name, formik.values[field.name] === "true" ? "false" : "true")
                            }}
                        />
                        <span className="field-checkbox-label-content">{label}</span>
                    </label>
                    {formik.submitCount && formik.errors[field.name] ? <span className="warning">{formik.errors[field.name]}</span> : null}
                    <span className="help-text">{field.helpText}</span>
                </div>
            )
        
        case "radio":
            return <FieldRadio formik={formik} field={field} autoFocus={autoFocus} />

        case "select":
            return <FieldSelect formik={formik} field={field} autoFocus={autoFocus} />

        case "date":

            var value = formik.values[field.name]

            // Support UK date string format as input. Output will always be ISO format.
            if (value?.includes("/")) {
                var split = formik.values[field.name]?.split("/")
                value = split?.reverse().join("-")
            }

            return (
                <label className="form-field field-date">
                    {label}

                    <input
                        type="date"
                        name={field.name}
                        autoFocus={autoFocus}
                        value={value}
                        onChange={(e) => {
                            // var split = e.target.value.split("-")
                            // var value = split.reverse().join("/")
                            // formik.setFieldValue(field.name, value)
                            var date = new Date(e.target.value)
                            
                            // Limit years to 4 digits
                            if (isValid(date) && date.getFullYear() > 9999) {
                                return
                            }
                            
                            formik.setFieldValue(field.name, e.target.value)
                        }} />

                    {field.helpText ? <span className="help-text" style={{marginTop: "4px"}}>{field.helpText}</span> : null}
                    <small className="warning">{formik.submitCount && formik.errors[field.name] ? formik.errors[field.name] : null}</small>

                </label>
            )

        case "number":
        case "text-area":
        default:

            var len = formik.values[field.name]?.length
            var helpText = field.helpText ? field.helpText : false

            if (field.type === "text-area") {
                helpText = `${len ? len : 0} characters ${helpText ? `\n${helpText}` : ""}`
            }

            return (
                <div className="form-field field-date" style={{display: "grid"}}>
                    <TextField
                        name={field.name}
                        value={formik.values[field.name]}
                        label={label}
                        type={field.type === "number" ? "number" : "text"}
                        variant={field.type === "text-area" ? "filled" : "standard"}
                        multiline={field.type === "text-area"}
                        rows={field.type === "text-area" ? 4 : 1}
                        autoFocus={autoFocus}
                        helperText={helpText}
                        error={formik.submitCount && formik.errors[field.name] ? formik.errors[field.name] : false}
                        onChange={formik.handleChange}/>

                    {/* {field.type === "text-area" ? <small>{len ? len : 0} characters</small> : null} */}

                    <small className="warning">{formik.submitCount && formik.errors[field.name] ? formik.errors[field.name] : null}</small>
                </div>
            )
    }
})