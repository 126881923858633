import { Button, TextField } from "@mui/material";
import React from "react";
import { context } from "..";
import { observer } from "mobx-react-lite";
import { IField, IPage, ITemplateBody } from "../interfaces";
import Ajax from "../Ajax";


export default observer(function DevTools () {

    const [loading, setLoading] = React.useState(false)
    const [templateJson, setTemplateJson] = React.useState(formatJson(context.template.json))
    const [answersJson, setAnswersJson] = React.useState<string>(JSON.stringify(context.answers, null, "\t"))
    const changesMade = formatJson(context.template.json) !== formatJson(templateJson)

    const overwriteAnswers = async (value: string = answersJson) => {
        setLoading(true)
        await Ajax.Questionnaire.Update(context.questionnaireUid, JSON.parse(value)).then((response) => {
            context.loadQuestionnaire()
        }).catch(() => {
            context.displayError("Failed to get reset answers")
        })
        setLoading(false)
    }

    const overwriteTemplate = async () => {
        setLoading(true)
        // Assign Page IDs
        // var newTempalteJson = await assignPageIDs(templateJson)
        // setTemplateJson(newTempalteJson)

        // await overwriteAnswers("{}")
        await Ajax.Template.Update(context.template.key, JSON.parse(templateJson)).then((response) => {
            context.loadQuestionnaire()
            setTemplateJson(formatJson(templateJson))
        }).catch((response) => {
            context.displayError(response.data?.detail ? response.data.detail : "Failed to get overwrite questionnaire")
        })
        setLoading(false)
    }

    return (
        <div className="dev-tools">
            <h1>Developer tools</h1>

            <p>Use these tools to build questionnaire templates. Any changes you make to this template will take effect on any questionnaires which already use this template.</p>

            <div className="form">
                <h3>{context.template?.displayName}</h3>
                <TextField
                    placeholder="Paste Template JSON here"
                    multiline
                    rows={18}
                    style={{width: "100%", background: "#dadada"}}
                    value={templateJson}
                    onChange={(e) => setTemplateJson(e.target.value)} />

                <div style={{display: "flex", gap: "10px", marginTop: "10px"}}>
                    <Button
                        color="info"
                        variant="contained"
                        className="btn btn-md"
                        onClick={async () => {
                            var newTempalteJson = await assignPageIDs(templateJson)
                            setTemplateJson(newTempalteJson)
                        }}>
                        Assign Page IDs
                    </Button>

                    <Button
                        color="primary"
                        variant="contained"
                        className="btn btn-md"
                        disabled={!changesMade}
                        onClick={() => setTemplateJson(formatJson(context.template.json))}>
                        Reset this form
                    </Button>

                    <Button
                        color="error"
                        variant="contained"
                        className="btn btn-md"
                        disabled={!changesMade || loading}
                        onClick={() => overwriteTemplate()}>
                        Overwrite template
                    </Button>
                </div>
            </div>

            <br/>

            <div className="form">
                <h3>You answers</h3>
                <TextField
                    placeholder="Paste Answers JSON here"
                    multiline
                    rows={18}
                    style={{width: "100%", background: "#dadada"}}
                    value={answersJson}
                    onChange={(e) => setAnswersJson(e.target.value)} />
                
                <div style={{display: "flex", gap: "10px", marginTop: "10px"}}>
                    <Button
                        color="primary"
                        variant="contained"
                        className="btn btn-md"
                        disabled={JSON.stringify(context.answers, null, "\t") === answersJson}
                        onClick={() => setAnswersJson(JSON.stringify(context.answers, null, "\t"))}>
                        Reset this form
                    </Button>

                    <Button
                        color="error"
                        variant="contained"
                        className="btn btn-md"
                        disabled={loading}
                        onClick={() => overwriteAnswers()}>
                        Overwrite Answers
                    </Button>
                </div>
            </div>

            {/* <div style={{width: "100%"}}>
                <PageSelector />
            </div> */}
        </div>
    )
})


const formatJson = (json: string): string => {
    return JSON.stringify(JSON.parse(json), null, "\t")
}


const assignPageIDs = async (templateJson: string): Promise<string> => {

    var templateBody: ITemplateBody = JSON.parse(templateJson)

    if (!templateBody.pages || !Array.isArray(templateBody.pages)) return null

    const traverse = async (pages: IPage[]) => {
        
        for (var p=0; p < pages.length; p+=1) {
        
            var page: IPage = pages[p]
            page.id = await generatePageId(page)
            
            if (page.fields && Array.isArray(page.fields)) {
                for (var f=0; f < page.fields.length; f+=1) {
                    
                    var field: IField = page.fields[f]
                    
                    if (field.conditional_pages) {
                        Object.values(field.conditional_pages).forEach((pageList: IPage[]) => {
                            traverse(pageList)
                        })
                    }
                }
            }
        }
    }

    await traverse(templateBody.pages)

    return JSON.stringify(templateBody, null, "\t")
}


const generatePageId = async (page: IPage) => {

    // Remove ID and conditional_pages
    var pageToHash = {
        ...page,
        id: null,
        fields: page.fields?.map(field => {
            return {...field, conditional_pages: null}
        })
    }

    const encoder = new TextEncoder();
    const data = encoder.encode(JSON.stringify(pageToHash));
    const hashBuffer = await crypto.subtle.digest("SHA-256", data)
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
    return hashHex
}


// const PageSelector = observer(() => {

//     const getAllPages = () => {
//         var pageList = []

//         const handlePages = (pages) => {
//             pages.forEach(page => {
//                 pageList.push(page)

//                 // Check for conditional pages
//                 page.fields?.forEach(field => {
//                     var value = context.answers[field.name]
//                     if (field.conditional_pages && value in field.conditional_pages) {
//                         var conditionalPages = field.conditional_pages[value]
//                         handlePages(conditionalPages)
//                     }
//                 })
//             })
//         }

//         handlePages(context.questionnaire.pages)

//         return pageList
//     }

//     var allPages = getAllPages()

//     return (
//         <FormControl variant="standard">
//             <InputLabel id="page-selector-label">Select a page to preview</InputLabel>
//             <Select
//                 labelId="page-selector-label"
//                 id="page-selector"
//                 value={context.pageIndex}
//                 // autoFocus={autoFocus}
//                 // error={formik.submitCount && formik.errors[field.name] ? formik.errors[field.name] : false}
//                 onChange={(e) => context.setPreviewPage(allPages[e.target.value])}>
//                     <MenuItem value={null}>
//                         <b>Current page</b>
//                     </MenuItem>
//                     {allPages.map((page, i) => (
//                         <MenuItem key={i} value={i}>
//                             {i} {page.title} {page.submitted ? <Icons.Tick style={{fontSize: "14px", color: "grey"}} /> : null}
//                         </MenuItem>
//                     ))}
//             </Select>
//         </FormControl>
//     )
// })