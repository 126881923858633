import * as Yup from 'yup'
import { Button, TextField } from "@mui/material";
import { useFormik } from "formik";
import Ajax from "../Ajax";
import { useParams } from "react-router-dom";
import React from 'react';
import { context } from '..';


export default function LoginPage () {

    const params = useParams()

	var formik = useFormik({
        initialValues: {
            accessCode: ""
        },
        validationSchema: Yup.object({
            accessCode: Yup.string().required("This field is required")
        }),
        onSubmit: async (values) => {
            await Ajax.Public.Login(params.questionnaireUid, values.accessCode).then((response) => {
                context.setToken(response.data.token)
            }).catch((response) => {
                console.log("Login failure", response)
                formik.setErrors({accessCode: response.data?.detail ? response.data.detail : "There was an error"})
            })
        }
    })

	return (
		<div>
			<h1 style={{fontSize: "50px"}}>Sign in</h1>

            <p>An access code is required in order to continue to the questionnaire.</p>
            <p>Access codes must be provided securely by the issuer of the questionnaire.</p>

            <form onSubmit={formik.handleSubmit} style={{maxWidth: "400px", display: "grid"}}>
                <div>
                    <TextField 
                        label="Enter access code" 
                        name="accessCode"
                        autoFocus
                        value={formik.values.accessCode} 
                        onChange={formik.handleChange} />
                    
                    <div className="warning">{formik.errors.accessCode}</div>
                </div>
                
                <br/>
                
                <Button 
                    type="submit"
                    disabled={!!(formik.isSubmitting || (formik.submitCount && !formik.isValid))}
                    variant="contained" 
                    color="success"
                    className="btn btn-lg">
                    Sign in
                </Button>
            </form>
		</div>
	)
}