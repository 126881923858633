import { observer } from "mobx-react-lite"
import { context } from "../.."
import Uploads from "./Uploads"
import PreviousAnswers from "./PreviousAnswers"
import { Button } from "@mui/material"
import React from "react"

export default observer(function PageFinal () {
    
    const { locked, submitting, uploads, loadQuestionnaire } = context

    React.useEffect(() => {
        // This is needed to refresh the uploads list
        loadQuestionnaire()
    }, [])

    return (
        <div className="questionnaire-page">
            <div>
                <h1>Check your answers</h1>

                <p>Thank you for completing this questionnaire. </p>

                {locked 
                    ? <p>This questionnaire is locked.</p>
                    : <p>Please check all answers before submitting. Once submitted, you will no longer be able to edit, and the information will be sent to your Legal Adviser.</p>
                }
            </div>

            {uploads?.length ? (
                <div>
                    <h2>Attachments</h2>
                    <Uploads />
                </div>
            ) : null}

            <div>
                <h2>Answers</h2>
                <PreviousAnswers forceShow={true} />
            </div>

            <Button 
                className="btn btn-lg" 
                color="success" 
                variant="contained" 
                disabled={submitting || locked} 
                onClick={() => context.submitQuestionnaire()}>
                Confirm answers and Submit
            </Button>
        </div>
    )
})