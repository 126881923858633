import { IPage } from '../../interfaces'
import Table from '../form/Table.tsx'
import FormFields from '../form/FormFields.tsx';
import { context } from '../../index.js';
import { observer } from 'mobx-react-lite';
import PreviousAnswers from './PreviousAnswers.tsx';

interface IPageProps {
    page: IPage;
}

export default observer(function Page ({page}: IPageProps) {

    const title = context.formatLabel(page.title)
    const description = context.formatLabel(page.description)

    return (
        <div className="questionnaire-page">
            <h2>{title}</h2>
            
            <div>
                {description ? <div dangerouslySetInnerHTML={{__html: description}}></div> : null}

                {page.table ? (
                    <Table 
                        table={page.table} 
                        onSubmit={(values) => {
                            context.submitPageAnswers(values, page.id)
                        }} /> 
                ) : (
                    <FormFields 
                        submitButtonText="Next" 
                        initialValues={{...context.answers}} 
                        fields={page.fields} 
                        onSubmit={(values) => {
                            context.submitPageAnswers(values, page.id)
                        }} />
                )}
            </div>

            <PreviousAnswers />
        </div>
    )
})