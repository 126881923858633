import { observer } from "mobx-react-lite";
import { context } from "..";
import { Icons } from "../misc/Icons";
import { Button } from "@mui/material";

export default observer(function Errors () {
    return (
        <div className="error-message-list">
            {context.errors.map((errorMessage, i) => (
                <div key={i} className="error-message">
                    <span>{errorMessage}</span>
                    
                    <Button onClick={() => context.dismissError(errorMessage)}>
                        <Icons.X />
                    </Button>
                </div>
            ))}
        </div>
    )
})