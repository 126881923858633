import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import { context } from "../.."
import React from "react"
import { observer } from "mobx-react-lite"
import { IFieldProps } from "../../interfaces"

export default observer(function FieldSelect ({formik, field, autoFocus = false}: IFieldProps) {

    var options = field.setListKey ? context.getListSet(field.setListKey) : field.options
        
    return (
        <FormControl variant="standard" className="form-field">
            <InputLabel id={field.name}>{field.label}</InputLabel>
            <Select
                labelId={field.name}
                id={field.name}
                name={field.name}
                value={formik.values[field.name]}
                autoFocus={autoFocus}
                error={formik.submitCount && formik.errors[field.name] ? formik.errors[field.name] : false}
                onChange={formik.handleChange}>
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>

                    {Object.keys(options).map((value, i) => {
                        var label = context.formatLabel(options[value])
                        // Don't show options with placeholders remaining
                        if (label.includes("{") || label.includes("}")) {
                            return <React.Fragment key={i}></React.Fragment>
                        }
                        return <MenuItem key={i} value={value}>{label}</MenuItem>
                    })}
            </Select>

            {field.helpText ? <span className="help-text" style={{marginTop: "4px"}}>{field.helpText}</span> : null}
            <small className="warning">{formik.submitCount && formik.errors[field.name] ? formik.errors[field.name] : null}</small>
        </FormControl>
    )
})